import { createInstance } from '@jonkoops/matomo-tracker-react';

const baseUrl = 'https://matomo.crdlux.de';

const matomoInstance = createInstance({
  urlBase: baseUrl,
  siteId: 1,
  trackerUrl: baseUrl + '/matomo.php', // optional, default value: `${urlBase}matomo.php`
  srcUrl: baseUrl + '/matomo.js', // optional, default value: `${urlBase}matomo.js`
  disabled: false, // optional, false by default. Makes all tracking calls no-ops if set to true.
  heartBeat: {
    // optional, enabled by default
    active: true, // optional, default value: true
    seconds: 10, // optional, default value: `15
  },
  linkTracking: true, // optional, default value: true
  configurations: {
    // optional, default value: {}
    // any valid matomo configuration, all below are optional
    disableCookies: true,
    setSecureCookie: true,
    setRequestMethod: 'POST',
  },
});

export default matomoInstance;
