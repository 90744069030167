import 'bootstrap/dist/css/bootstrap.min.css';
import { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { MatomoProvider } from '@jonkoops/matomo-tracker-react';

import matomoInstance from './matomo.config';

const Impressum = lazy(() => import('./views/Impressum'));
const About = lazy(() => import('./views/About'));
const Legal = lazy(() => import('./views/Legal'));
const AdminArea = lazy(() => import('./views/AdminArea'));
const MainPage = lazy(() => import('./views/MainPage'));
const PrivacyPolicy = lazy(() => import('views/AppContent/PrivacyPolicy'));
const TermsOfUse = lazy(() => import('views/AppContent/TermsOfUse'));
const Licenses = lazy(() => import('views/AppContent/Licenses'));

function App() {
  return (
    <MatomoProvider value={matomoInstance}>
      <Router>
        <Suspense>
          <Routes>
            <Route path="/impressum" element={<Impressum />} />
            <Route path="/about" element={<About />} />
            <Route path="/legal" element={<Legal />} />
            <Route path="/adminarea" element={<AdminArea />} />
            <Route path="/offer/:offerID" element={<MainPage />} />
            <Route
              path="/app/privacypolicy/:language?"
              element={<PrivacyPolicy />}
            />
            <Route path="/app/termsofuse/:language?" element={<TermsOfUse />} />
            <Route path="/applicenses" element={<Licenses />} />
            <Route path="*" element={<MainPage />} />
          </Routes>
        </Suspense>
      </Router>
    </MatomoProvider>
  );
}

export default App;
